.create {
  color: #555;
  max-width: 480px;
  margin: 60px auto;
}

.create label span {
  display: block;
  margin: 30px 0 10px;
}

.create p {
  margin-top: 10px;
  font-size: 0.8em;
}

.create .btn {
  display: block;
  width: 100px;
  font-size: 1em;
  color: #fff;
  padding: 8px 20px;
  border: 0;
  border-radius: 4px;
  background-color: #58249c;
  cursor: pointer;
  text-decoration: none;
  margin: 20px auto;
}

.create .btn.add {
  width: 120px;
}

.ingredients {
  display: flex;
  align-items: center;
}

.ingredients .btn {
  margin: 0 0 0 10px;
}

.ing:hover {
  color: crimson !important;
  cursor: pointer !important;
}

.btn.disabled {
  cursor: wait;
  width: 130px;
  pointer-events: none;
}

/* dark mode */
.create.dark .page-title {
  color: #ddd;
}

.create.dark span,
.create.dark p {
  color: #ccc;
}

.create.dark em {
  color: #eee;
}

.create.dark input,
.create.dark textarea {
  background-color: #555;
  border-color: #444;
  color: #ddd;
}

.create.dark input:focus,
.create.dark textarea:focus {
  outline: 2px solid black;
}