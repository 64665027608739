.navbar {
  background: #58249c;
  padding: 20px;
  color: #fff;
}
.navbar nav {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}
.navbar a.brand {
  margin-right: auto;
  color: #fff;
  text-decoration: none;
}
.navbar a:last-child {
  color: #fff;
  text-decoration: none;
  margin-left: 40px;
  padding: 8px;
  border: 1px solid #fff;
  border-radius: 4px;
}
.navbar a:last-child:hover {
  background: #fff;
  color: #333;
}
main {
  margin: 40px auto;
}